import { slugToTitle } from "../../../lib/utils"
import { usePropertyStore } from "../store/propertyStore"

const useMyAccSearch = () => {
  const {
    pDepartment,
    pType,
    selectedAreas,
    selectedAmenities,
    minPrice,
    maxPrice,
    minBedroom,
    maxBedroom,
    buildingType,
    minAreaSize,
    maxAreaSize,
  } = usePropertyStore()

  return {
    department: pDepartment,
    search_type: pType,
    areas: selectedAreas?.map((area) => area.name).join(","),
    feature: selectedAmenities?.map((amenity) => slugToTitle(amenity.value)).join(","),
    minPrice: minPrice?.value,
    maxPrice: maxPrice?.value,
    building: buildingType?.value,
    minBedroom: minBedroom?.value,
    maxBedroom: maxBedroom?.value,
    minAreaSize: minAreaSize?.value,
    maxAreaSize: maxAreaSize?.value,
  }
}

export default useMyAccSearch
