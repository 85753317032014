import { sort } from "@lib/utils"
import { graphql, useStaticQuery } from "gatsby"

const useBlogs = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlog(
        filter: { publish: { eq: true } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          ...blogCardFragment
        }
      }
    }
  `)

  const blogs = data?.allStrapiBlog?.nodes

  if (blogs) {
    blogs.sort((a, b) => sort(a?.rank, b?.rank))
  }

  return blogs
}

export default useBlogs
