import React from "react"
import "./NewsRelatedContact.scss"
import GGFXImage from "@components/elements/GGFXImage"
import { Link } from "gatsby"
import { newsAndInsightURL, teamsURL } from "@lib/urls"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import { addRollWhatsapp, getWhatsAppURL, getWhatsBaseURL, gtagReportConversion } from "@lib/utils"
import useDeviceMedia from "@hooks/useDeviceMedia"
import Animation from "@components/elements/Animation"
import TeamContactModalWithTrigger from "@components/TeamContactModal/TeamContactModal"

const NewsRelatedContact = ({ team, news }) => {
  const { isTablet } = useDeviceMedia()

  if (!team) return null
  const { name, designation, image, ggfx_results, strapi_id, slug, phone } =
    team

  return (
    <Animation className="related-contact-wrap">
      <p className="heading">Related Contact</p>
      <div className="team-info">
        <Link to={`${teamsURL + slug}/`} className="team-img img-zoom">
          <GGFXImage
            ImageSrc={image}
            altText={name}
            imagetransforms={ggfx_results}
            renderer="srcSet"
            imagename="team.image.tile_img"
            strapiID={strapi_id}
          />
        </Link>
        <div className="team-detail">
          <div>
            <Link to={`${teamsURL + slug}/`} className="name">
              {name}
            </Link>
            <p className="position">{designation}</p>
          </div>
          {!isTablet && (
            <div className="cta-section">
              <TeamContactModalWithTrigger
                team={team}
                className="button button-orange"
              >
                <span>Email</span>
                <MailIcon color="white" />
              </TeamContactModalWithTrigger>
              {phone && (
                <>
                  <a
                    href={`tel:${phone}`}
                    className="button button-orange"
                    onClick={() => gtagReportConversion(`tel:${phone}`)}
                  >
                    <span>Call</span>
                    <MobileIcon color="white" />
                  </a>
                  <a
                    href={getWhatsBaseURL(
                      phone,
                      `Hi, I'm interested in ${news.title} - ${
                        process.env.GATSBY_SITE_URL +
                        newsAndInsightURL +
                        news.slug
                      }/`
                    )}
                    className="button button-gray whatsapp-link"
                    target="_blank"
                    rel="noreferrer"
                    onClick={()=> addRollWhatsapp()}
                  >
                    <WhatsAppIcon />
                  </a>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Animation>
  )
}

export default NewsRelatedContact
