import React from "react"
import "./CTAText.scss"
import ArrowUpRightIcon from "@components/icons/ArrowUpRightIcon"
import { isEmpty } from "lodash"
import CTALink from "../CTALink"

const CTAText = ({ data, showIcon, newclass }) => {
  if (isEmpty(data)) return null

  const { cta, text, noCta } = data
  return (
    <div className="content-cta">
      {cta && <CTALink cta={cta} className={`${newclass ? newclass : "cta"}`} />}
      {noCta && <p className="cta">{noCta.cta_label}</p>}
      <p className="cta-text">
        <span>{text}</span>
        {showIcon && <ArrowUpRightIcon />}
      </p>
    </div>
  )
}

export default CTAText
