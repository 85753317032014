import React, { useState } from "react"
import "./TeamContactModal.scss"
import { Modal } from "react-bootstrap"
import CloseIcon from "@components/icons/CloseIcon"
import GGFXImage from "@components/elements/GGFXImage"
import { Link } from "gatsby"
import { teamsURL } from "@lib/urls"
import MobileIcon from "@components/icons/MobileIcon"
import TeamContactForm from "@components/forms/TeamContactForm"
import loadable from "@loadable/component"

// const TeamContactForm = loadable(() =>
//   import("@components/forms/TeamContactForm")
// )

export const TeamContactModal = ({ team, show, handleClose }) => {
  if (!team) return null

  const { name, image, slug, ggfx_results, strapi_id } = team

 
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="team-contact-modal"
    >
      <Modal.Body className="team-contact-modal-body">
        <div className="team-contact-modal-header">
          <div className="content-section">
            <h3 className="title">Speak to our team</h3>
            <p className="description">
              Please fill in your details below to start a conversation with{" "}
              {name}.
            </p>
          </div>
          <Link to={`${teamsURL + slug}/`} className="img-section img-zoom">
            <GGFXImage
              ImageSrc={image}
              altText={name}
              imagetransforms={ggfx_results}
              renderer="srcSet"
              imagename="team.image.tile_img"
              strapiID={strapi_id}
            />
          </Link>
          <CloseIcon onClick={handleClose} />
        </div>
        <TeamContactForm
          email={team.email}
          name={team.name}
          submitText="Start a conversation"
        />
      </Modal.Body>
    </Modal>
  )
}

const TeamContactModalWithTrigger = ({
  team,
  buttonText,
  children,
  className,
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <button
        className={className || "button button-gray trigger-button"}
        onClick={handleShow}
      >
        {children || (
          <>
          <MobileIcon />
            <span>{buttonText || "Talk to our team"}</span>
            
          </>
        )}
      </button>
      <TeamContactModal team={team} show={show} handleClose={handleClose} />
    </>
  )
}

export default TeamContactModalWithTrigger
