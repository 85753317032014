import React from "react"
import "./NewsLetterModule.scss"
import { Link } from "gatsby"
import { privacyPolicyURL, termsAndConditionsURL } from "@lib/urls"
import Animation from "@components/elements/Animation"
// import NewsLetterForm from "@components/forms/NewsLetterForm"
import loadable from "@loadable/component"

const NewsLetterForm = loadable(() =>
  import("@components/forms/NewsLetterForm")
)

const NewsLetterModule = () => {
  return (
    <Animation className="newsletter-wrap">
      <h3 className="title">Stay in the loop with our newsletter</h3>
      <p className="description">
        Get to know about the latest real estate insights.
      </p>
      <NewsLetterForm />
      <p className="terms-section">
        By clicking Submit, you agree to our{" "}
        <Link to={termsAndConditionsURL}>Terms & Conditions</Link> and{" "}
        <Link to={privacyPolicyURL}>Privacy Policy</Link>.
      </p>
    </Animation>
  )
}

export default NewsLetterModule
