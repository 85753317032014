import { graphql, useStaticQuery } from "gatsby"

const usePropertyBanners = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiGlobalModule {
        property_sale_banners {
          ...marketingBannerFragment
        }
        property_rent_banners {
          ...marketingBannerFragment
        }
        offplan_banners {
          ...marketingBannerFragment
        }
      }
    }
  `)
  const propertySaleBanners = data?.strapiGlobalModule?.property_sale_banners
  const propertyRentBanners = data?.strapiGlobalModule?.property_rent_banners
  const offplanBanners = data?.strapiGlobalModule?.offplan_banners

  return {
    propertySaleBanners,
    propertyRentBanners,
    offplanBanners,
  }
}

export default usePropertyBanners
