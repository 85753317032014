import React from "react"
import "./NewsCard.scss"
import { Link } from "gatsby"
import Animation from "@components/elements/Animation"
import GGFXImage from "@components/elements/GGFXImage"
import { newsAndInsightURL } from "@lib/urls"
import PlayVideoButton from "@components/elements/PlayVideo/PlayVideoButton"

const NewsCard = ({ news, index, noAnimation }) => {
  if (!news) return null

  const {
    title,
    tile_image,
    category,
    date,
    ggfx_results,
    strapi_id,
    slug,
    video_url,
  } = news


  const categoryText = category?.strapi_json_value?.[0]
  return (
    <Animation
      animateInType="up"
      delay={index * 200}
      className="news-card"
      wrapperClassName="news-card-wrapper"
      noAnimation={noAnimation}
    >
      <div className="img-section-wrap img-zoom">
        <Link to={`${newsAndInsightURL + slug}/`} className="img-section">
          {categoryText && <p className="img-tag">{categoryText}</p>}
          <GGFXImage
            ImageSrc={tile_image}
            altText={title}
            imagetransforms={ggfx_results}
            renderer="srcSet"
            imagename="blog.tile_image.blog_card"
            strapiID={strapi_id}
          />
        </Link>
        {video_url && <PlayVideoButton videoUrl={video_url} />}
      </div>
      <Link to={`${newsAndInsightURL + slug}/`} className="title">
        {title}
      </Link>
      <p className="date">{date}</p>
    </Animation>
  )
}

export default NewsCard
