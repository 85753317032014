import NewsCard from "@components/NewsCard/NewsCard"
import SliderModule from "@components/SliderModule/SliderModule"
import useBlogs from "@hooks/dataHooks/useBlogs"
import React from "react"
import "./MoreNewsSlider.scss"

const MoreNewsSlider = ({ strapiID }) => {
  const news = useBlogs()
  const filteredNews = news.filter((item) => item.strapi_id !== strapiID)

  const sliderChildren = filteredNews
    .slice(0, 6)
    .map((item) => <NewsCard key={item.id} news={item} />)

  return (
    <SliderModule
      title="May also interest you"
      sliderChildren={sliderChildren}
      wrapperClassname="more-nwes-wrap"
      size="lg"
      sectionClassname="section-p"
    />
  )
}

export default MoreNewsSlider
