import React from "react"
import "./NewsBanner.scss"
import { Link } from "gatsby"
import { teamsURL } from "@lib/urls"
import Animation from "@components/elements/Animation"

const NewsBanner = ({ news }) => {
  const { author, title, date } = news

  const category = news?.category?.strapi_json_value?.[0]

  return (
    <Animation className="new-banner-wrap">
      <h1 className="title">{title}</h1>
      <div className="info-section">
        {author && (
          <>
            <p className="author-info">
              by <Link to={`${teamsURL + author.slug}/`}>{author.name}</Link>
            </p>
            <span className="slash-divider">/</span>
          </>
        )}
        {date && <p className="date">{date}</p>}
        {category && (
          <>
            <span className="slash-divider">/</span>
            <p className="category">{category}</p>
          </>
        )}
      </div>
    </Animation>
  )
}

export default NewsBanner
