import React, { useState } from "react"
import "./DownloadReportModal.scss"
import { Modal } from "react-bootstrap"
import CloseIcon from "@components/icons/CloseIcon"
import MobileIcon from "@components/icons/MobileIcon"
import DownloadForm from "@components/forms/DownloadForm"
import loadable from "@loadable/component"

// const DownloadForm = loadable(() => import("@components/forms/DownloadForm"))

export const DownloadReportModal = ({ show, handleClose, file }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
       backdrop="static"
      className="team-contact-modal"
    >
      <Modal.Body className="team-contact-modal-body">
        <div className="team-contact-modal-header">
          <div className="content-section">
            <h3 className="title">Download Report</h3>
            {/* <p className="description">
              Please fill in your details below to start a conversation with{" "}
              {name}.
            </p> */}
          </div>
          <CloseIcon onClick={handleClose} />
        </div>
        <DownloadForm
          file={file}
          submitText="Download Report"
          noScrollOnSuccess
        />
      </Modal.Body>
    </Modal>
  )
}

const DownloadReportModalWithTrigger = ({
  buttonText,
  children,
  className,
  triggerClassName,
  file,
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <button
        className={
          className || triggerClassName || "button button-gray trigger-button"
        }
        onClick={handleShow}
      >
        {children || (
          <>
            <span>{buttonText || "Talk to our team"}</span>
            <MobileIcon />
          </>
        )}
      </button>
      <DownloadReportModal file={file} show={show} handleClose={handleClose} />
    </>
  )
}

export default DownloadReportModalWithTrigger
