import { DownloadReportModal } from "@components/DownloadReportModal/DownloadReportModal"
import useQueryParam from "@hooks/useQueryParam"
import React, { useState } from "react"

const AutoPopup = () => {
  const [popup] = useQueryParam("popup")
  const [fileURL] = useQueryParam("file_url")

  const [show, setShow] = useState(true)

  const handleClose = () => setShow(false)
  //   const handleShow = () => setShow(true)

  if (popup === "download-report" && fileURL) {
    return (
      <DownloadReportModal
        show={show}
        handleClose={handleClose}
        file={fileURL}
      />
    )
  }

  return null
}

export default AutoPopup
