import { sort } from "@lib/utils"
import { graphql, useStaticQuery } from "gatsby"

const useMarketingBanners = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiMarketingBanner(
        filter: { publish: { eq: true } }
        sort: { fields: rank, order: ASC }
      ) {
        nodes {
          ...marketingBannerFragment
        }
      }
    }
  `)

  const banners = data?.allStrapiMarketingBanner?.nodes

  if (banners) {
    banners.sort((a, b) => sort(a?.rank, b?.rank))
  }

  return banners
}

export default useMarketingBanners
