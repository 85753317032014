import React from "react"
import "./FAQ.scss"
import { Accordion, Container } from "react-bootstrap"
import CTAText from "@components/elements/CTAText/CTAText"
import { parseHTML } from "@lib/utils"
import Animation from "@components/elements/Animation"

const FAQ = ({ module, noContainer, bgColor }) => {
  if (!module) return null

  const { title, cta_text, faqs, bg_color } = module

  const ContainerComp = noContainer ? "div" : Container

  let wrapperClass = "faq-section-wrap"

  switch (bg_color) {
    case "white":
      wrapperClass += " white-bg section-m"
      break
    default:
      wrapperClass += " section-p"
      break
  }
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs?.map((faq) => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer?.data?.answer,
      },
    })),
  };
  return (
    <Animation animateInType="up" className={wrapperClass}>
      <ContainerComp className="faq-section-container">
        <h2 className="title">{title}</h2>
        <div className="faq-section">
          <Accordion defaultActiveKey={faqs?.[0]?.id}>
            {faqs?.map((faq) => (
              <Accordion.Item eventKey={faq.id} key={faq.id}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>
                  {parseHTML(faq.answer?.data?.answer)}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <CTAText data={cta_text} showIcon />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema) }}
        />
      </ContainerComp>
    </Animation>
  )
}

export default FAQ
