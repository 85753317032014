import React, { useState } from "react"
import clsx from "clsx"
import VideoModal from "./VideoModal"

const PlayVideoButton = ({ videoUrl, alternateButton }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!videoUrl) return null

  return isOpen ? (
    <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} videoUrl={videoUrl} />
  ) : (
    <button
      className={clsx("play-button", alternateButton && "alternate")}
      onClick={() => setIsOpen(true)}
      aria-label="play button"
    />
  )
}

export default PlayVideoButton
